/* eslint-disable */
const DESC_ME = {
ko_1983:
`안녕하세요, 저를 타임라인으로 소개해 보겠습니다.
`,
en_1983:
`Hello all! let me introduce myself : ). see the my profile timeline!!
`,
ko_2009_02:
`2009년에 처음으로 회사생활을 시작하였습니다.
첫 직장은 TmaxSoft라는 소프트웨어 회사입니다.
전임 연구원으로 사회생활 시작합니다.
`,
ko_2009_03:
`입사 후 처음으로 회사에서 프로젝트에 참여하였습니다.
제가 참여한 프로젝트는 TmaxOS라는 TmaxSoft의 OS 개발 프로젝트 입니다.
해당 프로젝트에서, MFC 공용 라이브러리를 개발하였습니다.
해당 라이브러리의 목적은 Window용 프로그램이 TmaxOS에서 구동되도록
해주는 역할을 합니다.
`,
ko_2010_01:
`회사가 바뀌었습니다.
S-core 저의 두번째 직장이고, 현재도 다니고 있는 직장입니다.
`,
ko_2010_07_20:
`저의 두번째 프로젝트이자, 바뀐 회사의 첫번째 프로젝트 입니다.
WAC은 (Wholesale Applications Community)의 약자입니다.
홈페이지는(http://www.wholesaleappcommunity.com/) 입니다.
제가 참여한 부분은, WAC Spec을 따르는 WEB-APP을 제작할 수 있는,
IDE을 제공하는 파트를 담당했습니다.
Eclipse 기반의 Plug-in 형태의 프로그램이고, 제가 처음 WEB World에 참여한 프로젝트 입니다.
물론 아직은 Java(Eclipse)를 사용했지만 말이죠...
`,
ko_2011_03:
`Tizen 1.0 프로젝트에 참여하였습니다.
저는 WAC 프로젝트 경험을 바탕으로 IDE팀에 배정되었습니다.
Tizen IDE 역시 Eclipse FW을 기반으로한 IDE 입니다.
Tizen IDE중 web app 개발 환경을 담당하였습니다. 특히 launch, debug 분야의 모듈을 담당하였습니다.
약 1년간 진행후, Tizen 1.0 버전을 성공적으로 relase 하였습니다.
Tizen 1.0 의 코드네임은 Larkspur 입니다.
Tizen 1.0 의 릴리즈 노트: (https://source.tizen.org/release/tizen-1.0)
`,
ko_2012_03:
`매우 행복하네요. 그동안의 경력을 인정받아서 승진했습니다.
저는 연구개발 직군이여서, 이제 '선임'이 되었습니다.
그래도 아직 파릇 파릇한 개발자 입니다.
`,
ko_2012_04:
`Tizen 2.0 Alpha 프로젝트에 참여하였습니다.
기존 1.0 프로젝트에서 발생한 버그 및 성능 개선등의 업무를 진행하였습니다.
저는 기존과 동일하게 IDE팀에 배속되어 업무를 진행하였습니다.
약 6개월정도 진행후, 2.0 Alpha을 성공적으로 relase하였습니다.
Tizen 2.0 Alpha 의 코드네임은 Magnolia 입니다.
Tizen 2.0 Alpha 의 릴리즈 노트: (https://source.tizen.org/ko/release/tizen-2.0-alpha)
`,
ko_2012_09:
`Tizen 2.0 프로젝트에 참여하였습니다.
저는 기존과 동일하게 IDE팀에서 업무를 진행하였습니다.
기존 Tizen의 버그 및 성능 개선 이외, HTML5/W3C API 지원등을 추가하였습니다.
본격적으로, 이런저런 디바이스에 실제 OS가 탑제되었습니다.
Tizen 2.0 의 릴리즈 노트: (https://source.tizen.org/ko/release/tizen-2.0)
`,
ko_2013_02:
`Tizen 2.1 Beta SDK 프로젝트에 참여하였습니다.
2.1 까지가 제가 참여한 Tizen 프로젝트 입니다. 현재는 Tizen 3.0M2 까지 진행되었습니다.
회사 생활을 시작한 이후 가장 오랜 기간 참여했던 프로젝트입니다.
오픈소스 프로젝트여서 아직도 코드에 저의 이름이 남아있는 부분도 있습니다.
물론 많은 부분이 새로 담당한 담당자로 바뀌었지만요...
대규모 프로젝트의 경험으로, 개인적으로는 얻은것도 많고, 느낀것도 많은 프로젝트였습니다.
Tizen 2.1 의 릴리즈 노트: (https://source.tizen.org/ko/release/tizen-2.1)
`,
ko_2013_03:
`축 결혼!, 결혼에 성공했습니다.
저의 인생에서 가장 행복한 순간중 하나입니다.
`,
ko_2013_08:
`기존 Web 개발을 위한 환경을 개발하다. Web에 대한 관심이 점점 높아졌습니다.
사내에서 팀을 이동할 기회가 생겨, Web의 세계로 본격적으로 들어가게된 시점입니다.
webida라고 하는 web 기반 IDE 프로젝트에 참여하였습니다.
webida는 open-source이고, 현재도 github에 코드가 존재합니다.
해당 프로젝트에서 Client을 담당하였습니다.
webida의 대부분의 Client UI을 작성하였습니다.
webida (https://github.com/webida)
`,
ko_2014_03:
`Web 에서 UI Prototyping을 하는 Web기반 UI Prototyping Tool을 개발하였습니다.
해당 프로젝트는 BtoB프로젝트로 납품한 회사 전용으로 사용되는 소프트웨어로 개발하였습니다.
기존의 UI Prototyping Tool와 비슷한 툴로 생각하면 됩니다. 다만 Web에서 되는.!!
이 프로젝트에서는 Modeling 부분을 담당하였습니다.
(어떤 그림을 모델화 하고, 해당 모델을 Web에서 UI로 표현하는 부분)
`,
ko_2014_11:
`Web 기반 Editor을 개발하였습니다.
Editor의 내부 Widget을 담당하여 Widget을 개발하였습니다.
대표적인 예로, Color-Picker, 글자 Bold만들기, Image D&D 등이 있습니다.
`,
ko_2015_03:
`Tizen의 Web 기반 IDE을 개발하였습니다.
정식으로 Tizen에 relase되지는 못하였지만.
Tizen개발을 eclipse가 아닌 web 상에서 가능하도록 한 프로젝트 입니다.
기존에 개발하였던 webida을 기반으로 작성하였습니다.
제가 담당한 부분은 manifest eidtor, project wizard, workspace입니다.
`,
ko_2016_01:
`Insator.io 프로젝트에 참여하였습니다.
정확하게는 insator.io 프로젝트중, DPD(Data Preperation Design) Tool의
개발에 참여하였습니다.
홈페이지(www.instor.io) 에서 DPD 설명 부분을 개발하였습니다.
제가 담당한 부분은, ETL Wizard, Template, Data Dashboard등 입니다.
`,
ko_2017_01:
`VSCode Tizen C# Extension 프로젝트에 참여하였습니다.
VSCode에서 VSCode의 command 팔렛트를 사용하여,
Tizen C# appication을 개발 할 수 있게 해주는 프로젝트입니다.
제가 담당한 부분은 (CommonUtil, Create, Build, Run, Packaging
Project Check)등 거의 대부분의 기능입니다.
`,
ko_2017_04:
`Smartthings Local SDK 프로젝트에 참여하였습니다.
atom 기반의 Smartthings Iot Device 개발 환경을 제공하는 프로젝트 입니다.
제가 담당한 부분은, Logger, Device Profile, Run As, CI(Jenkins), build, installer 입니다.
`,
ko_2018_01:
`Smartthings Web SDK 프로젝트에 참여하였습니다.
web 기반의 Smartthings Iot Device 개발 환경을 제공하는 프로젝트 입니다.
제가 담당한 부분은, Logger, Automation(OnBoarding), Hub Connected, React to Vue 입니다.
`,
ko_2018_08:
`Naver Maps Admin Tool 프로젝트에 참여하였습니다.
web 기반의 네이버 지도 데이터 관리 및 운영을 위한 관리툴 서비스 프로젝트 입니다.
제가 담당한 부분은, 관리툴 광통 관리툴서버(express.js), POI편집툴, 다국어 관리툴, 배너 관리툴,
지하철 메타 정보 관리툴)등 거의 대부분의 기능입니다.
`,
ko_2018_08_02:
`Naver Maps(Pc-V5) 프로젝트에 참여하였습니다.
Naver의 Pc판 지도 서비스로, 기존 flash 및 레스터 기반 지도를 개편하는 프로젝트 입니다.
제가 담당한 부분은, 날씨, 지도검색 Sync, 버전관리, OG Tag, 인쇄저장, Api Proxy서버, 입니다.
`,
ko_2019_05:
`Naver MyPlace 프로젝트에 참여하였습니다.
사용자가 방문한 곳을 영수증으로 체크하고, 해당 정보 기준으로 별점을 주고, 장소Set을 공유하는 서비스 입니다.
제가 담당한 부분은, ClientUI, GraphQL서버 개발, MyPlace 발견하기 관리툴 입니다.
`,
ko_2019_11:
`Naver Ketch App 프로젝트에 참여하였습니다.
지도 NativeApp의 대중교통 장소들에 대한 EndPage로 NativeApp의 webview로 나오는 컨텐츠를 담당하는 프로젝트 입니다.
제가 담당한 부분은, 지하철, 공항, 항만, 터미널의 End Page요소 입니다.
`,
ko_2020_01:
`Naver 버스터미널, 컨검,통검 엔드 프로젝트에 참여하였습니다. 네이버 기본검색(초록창)에서,
버스, 시외버스, 동서울터미널 와 같은 터미널 키워드 검색에 대한 컨텐츠를 제공하는 프로젝트 입니다.
제가 담당한 부분은, End(메인홈, 지도, 라우팅), CollectionUI, Presentation Server 입니다.
`,
ko_2020_11:
`Naver KTX(Korail), 컨검,통검 엔드 프로젝트에 참여하였습니다. 네이버 기본검색(초록창)에서,
기차예매, 서울역 와 같은 기차역 키워드 검색에 대한 컨텐츠를 제공하고, mobile, pc, in-app end을 모두 제공하는,
프로젝트 입니다. 동시에 Nave Pay와 연동하여, Korail기차 예매를 Naver화면에서 제공하는 프로젝트 입니다.
제가 담당한 부분은, End(메인홈, 지도, 라우팅), CollectionUI, Presentation Server 입니다.
`,
ko_2020_11_2:
`Naver Map Open api 서버 개선 프로젝트에 참여하였습니다. 호갱노노 같은 외부 밴더 및 개인사용자들이,
네이버 지도 Open api사용시, auth 체크 및 SDK을 서빙하는 서버입니다. 구 서버가 python2 로 되어 있어서,
FE팀에서 유지보수 가능한 Deno형태로 교체 작업을 하였습니다. 전체 아키텍쳐 및 api구현을 담당하였습니다.
`,
ko_2022_01:
`Naver Map app-end 하프뷰 프로젝트에 참여하였습니다. 네이버 지도 App 내의 교통(버스, 지하철, 공항, 길찾기)엔드는,
WebView로 구성되어 있습니다. 해당 WebView의 구현을 Full View모드와 Half View모드로 이원화 하여 지도를 좀 더 노출하고,
해당 기능으로 사용성을 높이는 프로젝트 입니다. 교통엔드 페이지의 하프뷰 구현을 담당하였습니다.
`,
ko_2022_03:
`Naver 지하철, 컨검,통검 엔드 개발 프로젝트에 참여하였습니다. 네이버 기본검색(초록창) 및 네이버 지도앱에서,
지하철 정보에 대한 컨텐츠를 제공하는 프로젝트 입니다. 이번 개편에서 지하철 실시간 도착정보를 추가하여 사용성을 높였습니다.
제가 담당한 부분은, End(메인홈, 지도, 라우팅, 다크모드), CollectionUI, Presentation Server 입니다.
`,
ko_2021_11_3:
`Naver Map pc v5 react개선 작업 프로젝트에 참여하였습니다. map.naver.com으로 대표되는 pc향 네이버 지도 서비스 입니다.
해당 서비스가 기존 angular구현체를, react향 구현체로 변경하여, 성능 및 유지보수 향상을 시키는 내용입니다.
제가 담당한 부분은, devOps 및 아키텍쳐 부분을 담당하였습니다.
`,
ko_2022_07:
`Naver Map 내차등록 프로젝트 프로젝트에 참여하였습니다. 네이버 지도 및 지도앱에서, 내 차량을 등록 및 편집하는 서비스 입니다.
내차 등록을 통해서, 네이버 지도 및 지도앱에서 길찾기(네비) 기능을 수행할때, 좀더 정확한 정보를 주게 됩니다.
제라 담당한 부분은 FE팀에서 관리하는 전체 End Page 통합화 및 내차등록 페이지 구현 부분입니다. nx을 사용하여 mono repo로 구성하였습니다.
`,
ko_2022_09:
`Naver Map 즐겨찾기 widget 프로젝트에 참여하였습니다. 네이버 지도의 저장(즐겨찾기)을 네이버 전사 서비스에서 사용 할 수 있게 하는 라이브러리성 서비스 입니다.
작은 용량을 위해서 preact, signal을 활용하였고, cdn 및 내장형 lib파일을 제공합니다. 네이버 검색의 구 프로젝트에서 활용 가능하도록 es5도 지원합니다.
전체 기능 구현, devOps(배포, 유지보수)을 담당 하였습니다.
`,
ko_2022_11:
`Naver Map Open api 서버 개선 작업 프로젝트에 참여하였습니다. 호갱노노 같은 외부 밴더 및 개인사용자들이,
네이버 지도 Open api사용시, auth 체크 및 SDK을 서빙하는 서버입니다. 구 서버가 python2 로 되어 있어서,
FE팀에서 유지보수 가능한 Deno형태로 교체 작업을 하였습니다. 전체 아키텍쳐 및 api구현을 담당하였습니다.
배포역시 개선하여 n2c(kubernates, hpa)을 도입하여, 실시간성 신규 버전 배포를 적용하였습니다.
`,
ko_2023_04:
`Naver Map 즐겨찾기 페이지 개발 프로젝트에 참여하였습니다. 저장리스트, 저장상세, 저장편집 페이지을 개발하였습니다.
solid.js, vite, fastify 기술을 도입하였습니다.
`,
ko_2023_06:
`Naver Map 실시간 교통정보 페이지 개발 프로젝트에 참여하였습니다. 실시간 교통정보 및 사고 정보, CCTV viewer을 구현하였습니다.
react, vite, fastify, pnpm 을 사용하였습니다.
`,
ko_2023_07:
`Naver Map 약관 페이지 개발 프로젝트에 참여하였습니다.
지도서비스, 지도위치서비스, 사용자 이동이력, ubi등의 약관을 관리하는 페이지 입니다.
전체 기능 구현 및 devOps을 담당하였습니다.
`,
ko_2024_01:
`Naver Map 약관 이벤트 페이지 개발 프로젝트에 참여하였습니다.
ubi 약관 활성화를 하여 보험사 연동 강화를 위한 이벤트 였습니다.
전체 기능 구현 및 devOps을 담당하였습니다.
`,
ko_2024_01_02:
`Naver Map 공항 엔드 개편 프로젝트에 참여하였습니다.
기존 레거시(바닐라js)을 react향으로 개편하였습니다.
전체 기능 구현 및 devOps을 담당하였습니다.
`,
ko_2024_01_03:
`Naver Map 지하철 엔드 실시간 위치 개편 프로젝트에 참여하였습니다.
지하철역 상세 엔드 화면에서, 실시간 지하철 위치 정보를 제공하여, 사용성을 높여주는 프로젝트입니다.
기능 구현 및 devOps을 담당하였습니다.
`,
ko_2024_04_01:
`Naver Map 저장페이지 광고 추가 및 사용자 공개 리스트 개발 프로젝트에 참여하였습니다.
사용자 리스트에 광고를 추가하고, 사용자 공개 리스트 화면을 제공하여, 사용자들간 공개 저장 리스트를 쉽게 공유 할 수 있도록 하였습니다.
기능 구현 및 devOps을 담당하였습니다.
`,
ko_2024_04_02:
`Naver Map ubi 약관 개편 개발 프로젝트에 참여하였습니다.
운전자 점수를 활용하여, 보험사와 연계, 보험상품 개발 및 제공을 하는 프로젝트 입니다.
기능 구현 및 devOps을 담당하였습니다.
`
}

const timeLineValues = [
    // {
    // date: '1983-02-26',
    // title: 'GyeongSeok Seo\' profile',
    // desc: DESC_ME.ko_1983,
    // descEn: DESC_ME.en_1983,
    // term: ''
    // },
{
    date: '2009-02-13',
    title: 'My First Job',
    desc: DESC_ME.ko_2009_02,
    term: 'February 2009 - July 2010'
}, {
    date: '2009-03-01',
    title: 'My First Project',
    desc: DESC_ME.ko_2009_03,
    term: 'March 2009 - July 27, 2010'
}, {
    date: '2010-07-01',
    title: 'Change Company',
    desc: DESC_ME.ko_2010_01,
    term: 'July 2010 - Now'
}, {
    date: '2010-07-20',
    title: 'WAC',
    desc: DESC_ME.ko_2010_07_20,
    term: 'July 2010 - February 27, 2011',
    image: 'wac-logo.jpg'
}, {
    date: '2011-03-01',
    title: 'Tizen 1.0',
    desc: DESC_ME.ko_2011_03,
    term: 'March 2011 - April 30, 2012',
    image: 'tizen-1.0.jpg'
}, {
    date: '2012-03-01',
    title: 'Promotion',
    desc: DESC_ME.ko_2012_03
}, {
    date: '2012-04-30',
    title: 'Tizen 2.0 Alpha',
    desc: DESC_ME.ko_2012_04,
    term: 'April 30, 2012 - September 25, 2012',
    image: 'tizen-2.0-Alpha.jpg'
}, {
    date: '2012-09-25',
    title: 'Tizen 2.0',
    desc: DESC_ME.ko_2012_09,
    term: 'September 25, 2012 - February 18, 2013',
    image: 'tizen-2.0.jpg'
}, {
    date: '2013-02-18',
    title: 'Tizen 2.1 Beta',
    desc: DESC_ME.ko_2013_02,
    term: 'February 18, 2013 - August 01, 2013',
    image: 'tizen-2.1-beta.jpg'
}, {
    date: '2013-03-23',
    title: 'Marriage',
    desc: DESC_ME.ko_2013_03,
    term: 'March 03, 2013 - Now'
}, {
    date: '2013-08-01',
    title: 'Webida',
    desc: DESC_ME.ko_2013_08,
    term: 'August 01, 2013 - March 01, 2014',
    image: 'webida-logo.jpg'
}, {
    date: '2014-03-01',
    title: 'UIP(UI Prototyping Tool)',
    desc: DESC_ME.ko_2014_03,
    term: 'March 01, 2014 - November 01,2014',
    image: 'uip.jpg'
}, {
    date: '2014-11-01',
    title: 'Cafe Web Editor',
    desc: DESC_ME.ko_2014_11,
    term: 'November 01,2014 - March 01, 2015',
    image: 'cafe-editor.jpg'
}, {
    date: '2015-03-01',
    title: 'Tizen Web SDK',
    desc: DESC_ME.ko_2015_03,
    term: 'March 01, 2015 - January 01, 2016',
    image: 'tizen-websdk.jpg'
}, {
    date: '2016-01-01',
    title: 'Insator - DPD',
    desc: DESC_ME.ko_2016_01,
    term: 'January 01, 2016 - December 31, 2016',
    image: 'insator.jpg'
}, {
    date: '2017-01-01',
    title: 'vscode for tizen extension',
    desc: DESC_ME.ko_2017_01,
    term: 'January 01, 2017 - April 01, 2017',
    image: 'vscode.jpg'
}, {
    date: '2017-04-01',
    title: 'Smartthings Local SDK',
    desc: DESC_ME.ko_2017_04,
    term: 'April 01, 2017 - December 31, 2017',
    image: 'smartthings-local.jpg'
}, {
    date: '2018-01-01',
    title: 'Smartthings Web SDK',
    desc: DESC_ME.ko_2018_01,
    term: 'January 01, 2018 - Now',
    image: 'smartthings-web.png'
}, {
    date: '2018-08-01',
    title: 'Naver Maps Admin Tool',
    desc: DESC_ME.ko_2018_08,
    term: 'August 01, 2018 - May 31, 2019',
    image: 'naver-map-admin.png'
}, {
    date: '2018-08-01',
    title: 'Naver Maps(Pc-V5)',
    desc: DESC_ME.ko_2018_08_02,
    term: 'August 01, 2018 - December 31, 2019',
    image: 'naver-map-pcv5.png'
}, {
    date: '2019-05-01',
    title: 'Naver MyPlace',
    desc: DESC_ME.ko_2019_05,
    term: 'May 01, 2019 - December 31, 2019',
    image: 'naver-myplace.jpg'
}, {
    date: '2019-11-01',
    title: 'Naver Ketch App',
    desc: DESC_ME.ko_2019_11,
    term: 'November 01, 2019 - December 31, 2019',
    image: 'naver-ketch-app.jpg'
}, {
    date: '2020-01-01',
    title: 'Naver 버스터미널, 컨검,통검 엔드',
    desc: DESC_ME.ko_2020_01,
    term: 'January 01, 2020 - October 31, 2020',
    image: 'naver-bus-terminal.png'
}, {
    date: '2020-11-01',
    title: 'Naver KTX(Korail), 컨검,통검 엔드',
    desc: DESC_ME.ko_2020_11,
    term: 'November 01, 2020 - December 31, 2021',
    image: 'naver-train.png'
}, {
    date: '2021-11-01',
    title: 'Naver Map Open API',
    desc: DESC_ME.ko_2020_11_2,
    term: 'November 01, 2021 - December 31, 2021',
}, {
    date: '2022-01-01',
    title: 'Naver Map app-end 하프뷰',
    desc: DESC_ME.ko_2022_01,
    term: 'January 01, 2022 - March 01, 2022',
    image: 'naver-pt-halfview.jpeg'
}, {
    date: '2022-03-01',
    title: 'Naver 지하철, 컨검,통검 엔드 개발',
    desc: DESC_ME.ko_2022_03,
    term: 'March 01, 2022 - July 01, 2022',
    image: 'naver-pt-subway.png'
}, {
    date: '2021-11-03',
    title: 'Naver Map pc v5 react개선 작업',
    desc: DESC_ME.ko_2021_11_3,
    term: 'November 01, 2021 - September 01, 2023',
    image: 'naver-pc-map.png'
}, {
    date: '2022-07-01',
    title: 'Naver Map 내차등록 프로젝트 수행',
    desc: DESC_ME.ko_2022_07,
    term: 'July 01, 2022 - August 31, 2022',
    image: 'naver-mycar.png'
}, {
    date: '2022-09-01',
    title: 'Naver Map 즐겨찾기 widget 개발',
    desc: DESC_ME.ko_2022_09,
    term: 'September 01, 2022 - January 31, 2023',
    image: 'naver-save-widget.png'
}, {
    date: '2022-11-01',
    title: 'Naver Map Open api 서버 개선',
    desc: DESC_ME.ko_2022_11,
    term: 'November 01, 2022 - April 31, 2023'
}, {
    date: '2023-04-01',
    title: 'Naver Map 즐겨찾기 페이지 개발',
    desc: DESC_ME.ko_2023_04,
    term: 'April 01, 2023 - June 31, 2023',
    image: 'naver-save-page.png'
}, {
    date: '2023-06-01',
    title: 'Naver Map 실시간 교통정보 페이지 개발',
    desc: DESC_ME.ko_2023_06,
    term: 'June 01, 2023 - September 31, 2023',
    image: 'naver-realtime-traffic.png'
}, {
    date: '2023-07-01',
    title: 'Naver Map 약관 페이지 개발',
    desc: DESC_ME.ko_2023_07,
    term: 'July 01, 2023 - December 31, 2023',
    image: 'naver-agreeterm.png'
}, {
    date: '2024-01-01',
    title: 'Naver Map 약관 이벤트 페이지 개발',
    desc: DESC_ME.ko_2024_01,
    term: 'January 01, 2024 - March 31, 2024',
    image: 'naver-agreeterm-event.png'
}, {
    date: '2024-01-01',
    title: 'Naver Map 공항 엔드 개편',
    desc: DESC_ME.ko_2024_01_02,
    term: 'January 01, 2024 - March 31, 2024',
    image: 'naver-airport.png'
}, {
    date: '2024-01-01',
    title: 'Naver Map 지하철 엔드 실시간 위치 개편',
    desc: DESC_ME.ko_2024_01_03,
    term: 'January 01, 2024 - April 31, 2024',
    image: 'naver-realtime-subway.png'
}, {
    date: '2024-04-01',
    title: 'Naver Map 저장페이지 광고 추가 및 사용자 공개 리스트 개발',
    desc: DESC_ME.ko_2024_04_01,
    term: 'April 01, 2024 - (진행중)',
    image: 'naver-ad-save-page.png'
}, {
    date: '2024-04-01',
    title: 'Naver Map ubi 약관 개편 개발',
    desc: DESC_ME.ko_2024_04_02,
    term: 'April 01, 2024 - (진행중)',
    image: 'naver-ubi-302.png'
}];

module.exports = {
    TIME_LINE_VALUES: timeLineValues
}
